import { Link, PageProps } from "gatsby";
import * as React from "react";
import Button from "src/components/Button";
import CourseProgressBar from "src/components/CourseProgressBar";
import PageHeader from "src/components/PageHeader";
import Typography from "src/components/Typography";
import { Course } from "src/types";
import Homeworks from "../Lesson/Homeworks";
import classes from "./CourseContent.module.css";
import LessonsBlock from "./LessonsBlock";

interface Props extends PageProps, Course {
  updatePage(): Promise<void>;
}

class CourseContent extends React.Component<Props, any> {
  public render() {
    const { props } = this;
    return (
      <div>
        <PageHeader text={props.name}>
          <Link
            to={`/school/about/${props.latinName}`}
            className={classes.linkAboutButton}
          >
            <Button>О курсе</Button>
          </Link>
        </PageHeader>
        <div>
          {props.lessonsBlocks.map((curr, i) => (
            <LessonsBlock key={i} {...curr} courseId={props._id} />
          ))}
        </div>
        {props.lessonsWithHomework?.length ? (
          <Homeworks
            description={
              "Тут ты можешь загрузить домашнее задание к любому заданию из курса"
            }
            courseId={props._id}
            afterSubmit={this.props.updatePage}
            homeworks={props.lessonsWithHomework
              ?.map((curr) => curr.homework)
              .filter((c) => !!c)}
            lessonOptions={props.lessonsWithHomework?.map((curr) => {
              return {
                value: curr._id,
                label: curr.name,
              };
            })}
          />
        ) : null}
      </div>
    );
  }
}

export default CourseContent;
