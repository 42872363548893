import * as React from "react";
import SectionHeader from "src/components/SectionHeader";
import LessonAnnounce from "src/routes/School/LessonAnnounce";
import { LessonsBlock as LessonsBlockType } from "src/types";
import classes from "./LessonsBlock.module.css";

interface Props extends LessonsBlockType {
  courseId: string;
}

const LessonsBlock: React.SFC<Props> = (props) => {
  return (
    <section className={classes.block}>
      <SectionHeader header={props.name} />
      <div className={classes.mappedAnnounces}>
        {props.lessons.map((cur, i) => (
          <LessonAnnounce
            key={cur._id}
            announce={{
              img: cur.img,
              announceDescription: cur.announceDescription,
              name: cur.name,
            }}
            link={`/school/lessons/${props.courseId}/${cur._id}`}
            isWebinar={!!cur.isWebinar}
            hasHomework={!!cur.lessonTask?.description}
          />
        ))}
      </div>
    </section>
  );
};

export default LessonsBlock;
